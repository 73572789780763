export const mockRapXML = `<?xml version="1.0" encoding="UTF-8"?>
<html xmlns:cch="http://www.dps.state.nm.us/cch/1.0.9"
    xmlns:j="http://niem.gov/niem/domains/jxdm/4.1" xmlns:nc="http://niem.gov/niem/niem-core/2.0"
    xmlns:csl="http://www.w3.org/1999/XSL/Format">
    <head>
        <style type="text/css">body{font-family:courier; font-size:11pt;}</style>
    </head>
    <body>
        <div>RELEASED TO: NM0010100</div>
        <table align="center">
            <tr align="center">
                <td>State of New Mexico</td>
            </tr>
            <tr align="center">
                <td>DEPARTMENT of PUBLIC SAFETY</td>
            </tr>
            <tr align="center">
                <td>Law Enforcement Records Bureau</td>
            </tr>
            <tr align="center">
                <td>P.O. BOX 1628</td>
            </tr>
            <tr align="center">
                <td>Santa Fe, NM 87504-1628</td>
            </tr>
            <tr align="center">
                <td>(505) 827-9181 xx FAX (505) 827-3388</td>
            </tr>
        </table>
        <div align="left">BECAUSE ADDITIONS OR DELETIONS MAY BE MADE AT ANY TIME, A NEW COPY SHOULD
            BE REQUESTED WHEN NEEDED FOR SUBSEQUENT USE. ONLY FINGERPRINT CARD SUPPORTED ARREST
            INFORMATION IS PROVIDED ON THIS ARREST REPORT. WHEN AN EXPLANATION OF A CHARGE OR
            DISPOSITION IS NEEDED, COMMUNICATE DIRECTLY WITH THE AGENCY THAT FURNISHED THE DATA TO
            THE STATE CENTRAL REPOSITORY. THERE IS NO GUARANTEE THAT THIS RECORD RELATES TO THE
            PERSON YOU ARE INTERESTED IN WITHOUT FINGERPRINT VERIFICATION (A FINGERPRINT CARD SHOULD
            BE SUBMITTED FOR POSITIVE FINGERPRINT COMPARISON). THIS RESPONSE FOR ARREST RECORD
            INFORMATION IS BASED ON A SEARCH OF THE INFORMATION PROVIDED AND IS IN RESPONSE TO YOUR
            REQUEST PURSUANT TO NMSA 29-10-6(a) OF THE ARREST RECORD INFORMATION ACT, OR BECAUSE OF
            A STATUTORY REQUIREMENT. THE INFORMATION PROVIDED IN THIS REPORT IS BASED ONLY ON
            FINGERPRINT SUPPORTED INFORMATION. THIS INFORMATION IS "CONFIDENTIAL". DISSEMINATION OR
            THE REVEALING OF THE CONTENTS THEREOF, EXCEPT AS PROVIDED BY LAW, IS UNLAWFUL. QUESTIONS
            REGARDING AN ARREST OR DISPOSITION SHOULD BE DIRECTED TO THE ARRESTING AGENCY LISTED ON
            THE REPORT.</div>
        <table width="100%">
            <tr>
                <td>SID:XXXXXXXXXXX</td>
                <td>FBI:XXXXXXXXX</td>
                <td>SOC:XXX-XX-XXXX</td>
            </tr>
            <tr>
                <td>LastnameXXXXXX</td>
                <td>Firstname:XXXXXXXX</td>
                <td>DOB:XX/XX/XXXX</td>
            </tr>
            <tr>
                <td>SEX:X</td>
                <td>Race:XXXXX</td>
                <td>HGT:XXX</td>
            </tr>
            <tr>
                <td>Eyes:XXXXX</td>
                <td>Hair:XXXXX</td>
                <td>WGT:XXX</td>
            </tr>
        </table>
        <table width="100%">
            <tr>
                <td>DOB</td>
                <td>Soc</td>
                <td>Aliases</td>
            </tr>
            <tr>
                <td>----------</td>
                <td>-----------</td>
                <td>-----------</td>
            </tr>
            <tr>
                <td>XX/XX/XXXX</td>
                <td>XXX-XX-XXXX</td>
                <td>XXXXXXX, XXXXX</td>
            </tr>
            <tr>
                <td>XX/XX/XXXX</td>
                <td />
                <td>XXXXXXXX,

                    XXXXXX </td>
            </tr>
        </table>
        <div>RECORD INFORMATION</div>
        <div>------------------------------------------------------------------</div>
        <table width="100%">
            <tr>
                <td>Arrest#1</td>
                <td colspan="2">Arrested OR Received:05/12/2023</td>
            </tr>
            <tr>
                <td colspan="2" />
                <td>State Tracking #:XXXXXXXXXXX</td>
            </tr>
            <tr>
                <td colspan="3">ORI:NM0010100 - ALBUQUERQUE POLICE DEPARTMENT</td>
            </tr>
            <tr>
                <td>Name:XXXXX,XXXXXX </td>
                <td>SOC:XXX-XX-XXXXX</td>
                <td>DOB:XX/XX/XXXX</td>
            </tr>
            <tr>
                <td colspan="3">
                    <table>
                        <tbody>
                            <tr>
                                <td>Charge#:1</td>
                                <td>WARRANT XXXXXXXXXX </td>
                                <td>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:2</td>
                                <td>WARRANT XXXXXXXXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:3</td>
                                <td>XXXXXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>XXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:5</td>
                                <td>XXXXXXXXXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>Arrest#2</td>
                <td colspan="2">Arrested OR Received:XX/XX/XXXX</td>
            </tr>
            <tr>
                <td colspan="2" />
                <td>State Tracking #:XXXXXXXXXXXXXX</td>
            </tr>
            <tr>
                <td colspan="3">ORI:NM0010100 - ALBUQUERQUE POLICE DEPARTMENT</td>
            </tr>
            <tr>
                <td>Name:XXXXXXX,XXXXXXXXX </td>
                <td>SOC:</td>
                <td>DOB:xx/xx/xxxx</td>
            </tr>
            <tr>
                <td colspan="3">
                    <table>
                        <tbody>
                            <tr>
                                <td>Charge#:1</td>
                                <td />
                                <td>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td>Arrest#3</td>
                <td colspan="2">Arrested OR Received:XX/XX/XXXX</td>
            </tr>
            <tr>
                <td colspan="2" />
                <td>State Tracking #:XXXXXXXXXXXXXXX</td>
            </tr>
            <tr>
                <td colspan="3">ORI:NM0010100 - ALBUQUERQUE POLICE DEPARTMENT</td>
            </tr>
            <tr>
                <td>NameXXXXX,XXXXXXXXX </td>
                <td>SOC:XXX-XX-XXXX</td>
                <td>DOB:XX/XX/XXXX</td>
            </tr>
            <tr>
                <td colspan="3">
                    <table>
                        <tbody>
                            <tr>
                                <td>Charge#:1</td>
                                <td />
                                <td>XXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:2</td>
                                <td />
                                <td>XXXXXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:3</td>
                                <td />
                                <td>XXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:4</td>
                                <td />
                                <td>XXXXXXXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:5</td>
                                <td>WARRANT XXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                            <tr>
                                <td>Charge#:6</td>
                                <td>XXXXXXXXXXXXXXXXXXXXXXXXXXXXX</td>
                                <td>XXXXXXXXXXXXXXXXXXX</td>
                            </tr>
                            <tr>
                                <td colspan="3">Dispo:</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </table>
        <div align="center">*** END OF REPORT ***</div>
    </body>
</html>`